import logo from './assets/images/dizidesert_black.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" height="auto" width="100%" />
        <p>
          Website Coming Soon 01 Jan 2025
        </p>
      </header>
    </div>
  );
}

export default App;
